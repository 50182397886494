body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  margin-left: 80px;
  padding-left: 15px;
  border-left: 1px solid #ccc;
  font-family: 'Open Sans', sans-serif;

  .logo-image{
    position: fixed;
    top: 20px;
    left: 10px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
