.image-with-description {
  margin-bottom: 36px;

  img {
    margin-bottom: 8px;
  }

  .label {
    padding: {
      left: 10px;
      right: 10px;
    }
  }
}