.order {
  label {
    font-size: 0.8rem;
  }
  input {
    width: 15rem;
  }

  select {
    margin-right: 10px;
    vertical-align: center;
  }

  input[type="checkbox"] {
    width: 1rem;
  }

  textarea {
    height: 5rem;
    width: 15rem;
  }

  &_form-input {
    margin-bottom: 0.7rem;
  }

  &_validation-error {
    font-size: 0.8rem;
    display: block;
    margin: {
      top: 0.3rem;
      bottom: 1rem;
      left: 0.2rem;
    }
    max-width: 15rem;
    color: red;
  }

  &_help-text {
    margin: {
      top: 0.2rem;
      left: 0.2rem;
    }
    font-size: 0.8rem;
  }

  &_toggle-terms {
    font-size: 0.8rem;
  }
}